import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { fetcher } from "../api/ApiHook";
import { FORGET_PASSWORD } from "../api/ApiConstants";
import Loader from "../Loader";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async () => {
    let method = "POST";
    let payload = JSON.stringify({
      email: email,
      domain: "tasc360",
    });
    try {
      setLoading(true);
      const response = await fetcher(FORGET_PASSWORD, method, payload);
      if (response.status) {
        toast.success("Mail Sent Successfully.");
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.log(err, "error");
    } finally {
      setLoading(false);
    }
  };

  const buttonDisable = () => {
    const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,6}$/;
    if (email && emailRegex.test(email)) {
      return false;
    }
    return true;
  };
  return (
    <div className="inc-auth-container">
      <div className="tw-container tw-mx-auto tw-min-h-screen tw-flex tw-items-center tw-justify-center tw-text-center">
        {loading && <Loader />}
        <div className="cover-container tw-w-full tw-h-full tw-pb-2">
          <div>
            <div className="form-container tw-max-w-[440px] tw-bg-white tw-mx-auto">
              <div>
                <div>
                  <img
                    src="/assets/1.png"
                    alt="TASC logo"
                    className="tw-mx-auto tw-max-w-[220px]"
                  />
                </div>
                <div className="tw-text-[24px] ff-ws tw-mb-2 text-inc-tundora tw-font-[600]">
                  Forgot Password
                </div>
              </div>
              <div class="form-auth tw-w-full tw-mx-auto tw-flex tw-flex-col tw-gap-[15px]">
                <input
                  placeholder="Email"
                  type="email"
                  className="form-control"
                  value={email}
                  onChange={handleEmail}
                />
                <button
                  id="login-button"
                  className="btn btn-to-link btn-secondary btn-gradiant tw-mt-4 tw-flex tw-items-center bg-inc-orange"
                  type="button"
                  disabled={buttonDisable()}
                  onClick={handleSubmit}
                >
                  <span className="tw-font-[700]">Email me a recovery link</span>
                  <span class="icon-btn_track" style={{ marginLeft: "10px" }}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-arrow-right-circle-fill"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                    </svg>
                  </span>
                </button>
                <div class="tw-flex tw-justify-center tw-w-full tw-mt-2">
                  <a href="/" className="tw-text-[13px] tw-underline text-inc-tundora">
                    Back to login?
                  </a>
                </div>
                <div
                  className="tw-mt-3 tw-text-[13px] ff-ws text-inc-tundora"
                  style={{ color: "grey" }}
                >
                  Powered by TASC Outsourcing®
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position="bottom-right" />
      </div>
    </div>
  );
};

export default ForgotPassword;
