import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { fetcher, getUserDetails } from "../api/ApiHook";
import { USER_LOGIN } from "../api/ApiConstants";
import Loader from "../Loader";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [userDetails, setUserDetails] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    let method = "POST";
    let payload = JSON.stringify({
      email: userDetails.email,
      password: userDetails.password,
      domain: "tasc360",
    });

    try {
      setLoading(true);
      const response = await fetcher(USER_LOGIN, method, payload);
      if (response.status) {
        toast.success("Logged In Successfull.");
        localStorage.setItem("userToken", response.token);
        localStorage.setItem("role", response.role);
        await getUserDetails();
        if (response.role === "customer") {
          setTimeout(() => {
            navigate("/tasc360/home");
          }, 1000);
        } else {
          setTimeout(() => {
            navigate("/tasc360/admin-setting");
          }, 1000);
        }
      } else {
        toast.error(response.message);
      }
    } catch (err) {
      console.log(err, "error");
      toast.error("Login Failed.");
    } finally {
      setLoading(false);
    }
  };

  const handleChangeUserDetails = (e, filter) => {
    setUserDetails({ ...userDetails, [filter]: e.target.value });
  };

  const checkEmailIsFilledAndValid = () => {
    const email = userDetails.email;
    const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,6}$/;
    if (email && emailRegex.test(email)) {
      return false;
    }
    return true;
  };

  const checkPasswordIsFilledValid = () => {
    const password = userDetails.password;
    if (password.length > 6) {
      return false;
    }
    return true;
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      if (!checkEmailIsFilledAndValid() && !checkPasswordIsFilledValid()) {
        handleSubmit();
      }
    }
  };

  useEffect(() => {
    let role = localStorage.getItem("role");
    if (role === "customer") {
      navigate("/tasc360/home");
    }

    if (role === "superAdmin" || role === "admin") {
      navigate("/tasc360/admin-setting");
    }
  }, []);
  return (
    <div className="inc-auth-container">
      <div className="tw-container tw-mx-auto tw-min-h-screen tw-flex tw-items-center tw-justify-center tw-text-center">
        {loading && <Loader />}
        <div className="cover-container tw-w-full tw-h-full tw-pb-2">
          <div>
            <div className="form-container tw-max-w-[440px] tw-bg-white tw-mx-auto">
              <div>
                <div>
                  <img
                    src="/assets/1.png"
                    alt="TASC logo"
                    className="tw-mx-auto tw-max-w-[220px]"
                  />
                </div>
                <div className="tw-text-[24px] ff-ws tw-mb-2 text-inc-tundora tw-font-[600]">
                  Sign In
                </div>
              </div>
              <div className="form-auth tw-w-full tw-mx-auto tw-flex tw-flex-col tw-gap-[15px]">
                <form
                  className="form-auth tw-w-full tw-mx-auto tw-flex tw-flex-col tw-gap-[15px]"
                  onKeyDown={handleKeyPress}
                >
                  <input
                    placeholder="Email"
                    type="email"
                    value={userDetails.email}
                    onChange={(e) => handleChangeUserDetails(e, "email")}
                    className="form-control"
                  />
                  <div
                    className="input-group tw-flex-nowrap"
                    id="password-filled"
                  >
                    <input
                      className="tw-w-full form-control !tw-rounded-l-[50px] !tw-rounded-r-[0px]"
                      id="input-password"
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      name="password"
                      value={userDetails.password}
                      onChange={(e) => handleChangeUserDetails(e, "password")}
                    />
                    <span
                      className="tw-text-xl encrypted tw-py-[5px] tw-px-[10px] tw-border tw-border-[#dee2e6]"
                      style={{
                        cursor: "pointer",
                        borderRadius: "0 50px 50px 0px",
                      }}
                    >
                      {showPassword ? (
                        <i
                          className="bi bi-eye-fill"
                          onClick={() => setShowPassword(false)}
                        ></i>
                      ) : (
                        <i
                          className="bi bi-eye-slash-fill"
                          onClick={() => setShowPassword(true)}
                        ></i>
                      )}
                    </span>
                  </div>
                  <button
                    id="login-button"
                    className="btn btn-to-link btn-secondary btn-gradiant tw-mt-6 tw-flex tw-items-center"
                    type="button"
                    onClick={handleSubmit}
                    style={{
                      opacity:
                        checkEmailIsFilledAndValid() ||
                        checkPasswordIsFilledValid()
                          ? "0.65"
                          : "",
                    }}
                    disabled={
                      checkEmailIsFilledAndValid() ||
                      checkPasswordIsFilledValid()
                    }
                  >
                    <span className="tw-font-[700]">Sign In</span>
                    <span
                      className="icon-btn_track"
                      style={{ marginLeft: "10px" }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-arrow-right-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                      </svg>
                    </span>
                  </button>
                  <div className="tw-flex tw-justify-between tw-items-start tw-w-full tw-mt-2">
                    <a
                      href="/forget-password"
                      className="tw-text-[13px] tw-underline text-inc-tundora"
                    >
                      Forgot Password?
                    </a>
                    <a
                      href="/signup"
                      className="tw-text-[13px] tw-underline text-inc-tundora"
                    >
                      Create New Account?
                    </a>
                  </div>
                  <div
                    className="tw-mt-3 tw-text-[13px] ff-ws"
                    style={{ color: "grey" }}
                  >
                    Powered by TASC Outsourcing®
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer position="bottom-right" />
      </div>
    </div>
  );
};

export default Login;
